.about-app {
  padding: 30px 0px 0px;
  box-sizing: border-box;

  @media screen and (max-width: 640px) {
    padding-bottom: 100px;
  }

  &__content {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1170px;
    margin: auto;
  }

  &__video {
    max-width: 768px;
    margin: 30px auto;

    & .box-player {
      padding-top: 56.25%; // Percentage ratio for 16:9
      position: relative; // Set to relative

    }

    & iframe {
      position: absolute; // Scaling will occur since parent is relative now
      top: 0;
      width: 100%;
    }

  }


  &__col {
    position: relative;
    width: 33%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  &__showbtn {
    display: none;
    @media screen and (max-width: 640px) {
      display: block;
      max-width: 300px;
      width: 100%;
      height: 45px;
      border-radius: 10px;
      background-color: #2ba3f1;
      color: #fff;
      font-family: FuturaMedium;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      margin: 30px auto 0;
      text-transform: uppercase;
    }
  }
}

.about-app-card {
  position: relative;
  max-width: 320px;
  width: 100%;
  height: 500px;
  box-sizing: border-box;
  margin-bottom: 30px;
  overflow: hidden;
  @media screen and (max-width: 640px) {

    margin: 30px auto 0px;
  }

  &__img {
    width: 90%;
    margin: auto;
    display: block;
  }

  &__body {
    position: absolute;
    bottom: -5px;
    height: 180px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 30px;
    background-color: #2ba3f1;
    margin-top: -20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    margin-bottom: 5px;
    @media screen and (max-width: 640px) {
      height: 130px;
    }
  }

  &__text {
    position: absolute;
    width: 70%;
    font-family: FuturaMedium;
    font-size: 32px;
    line-height: 25px;
    color: #fff;
    margin: 0;
    text-align: center;
    transition: 1s;
    opacity: 0;

    &-active {
      opacity: 1;
      transition: 1s;
    }

    @media screen and (max-width: 640px) {
      font-size: 22px;
    }
  }
}

.totop {
  width: 50px;
  height: 50px;
  background-color: inherit;
  border: none;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
}
