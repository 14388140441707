.video{
    .title-h2{
        color: #fff;
        @media screen and (max-width: 640px) {
            text-align: center;
        }
    }
    &-wrapper{
        background-color: #2ba3f1;
        box-sizing: border-box;
        padding: 30px 0px;
    }
    &__buttons{
        position: relative;
        max-width: 960px;
        width: 100%;
        margin: 30px auto;
        display: flex;
        align-items: center;

        .slick-slider{
            max-width: 780px;
            width: 100%;
            margin: auto;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }
        
        .slick-current .video__btn{
            background-color: #2ba3f1;
            color: #fff;
        }
        .video__btnNext,
        .video__btnPrev{
            position: absolute;
            background-color: inherit;
            border: none;
            & svg {
                cursor: pointer;
            }
            &:focus{
               outline: none;
            }
            @media screen and (max-width: 640px) {
                position: static;
            }
        }
        .video__btnNext{
            right: -70px;
        }
        .video__btnPrev{
            left: -70px;
        }
    }
    &__content{
        max-width: 960px;
        width: 100%;
        margin: 30px auto;
        background-color: #dff1fd;
        border-radius: 30px;
        box-sizing: border-box;
        padding: 20px;
        @media screen and (max-width: 640px) {
            padding: 20px 0px;
        }
    }
    &__head{
        margin: 0;
        font-family: FuturaMedium;
        font-size: 24px;
        color: #333;
        @media screen and (max-width: 640px) {
            font-size: 18px;
        }
    }
    &__categories{
        font-family: FuturaMedium;
        font-size: 22px;
        color: #333;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 5px;
        @media screen and (max-width: 640px) {
            font-size: 18px;
        }
    }
    &__iconCat {
        height: 20px;
        margin-right: 10px;
        @media screen and (max-width: 640px) {
            width: 20px;
        }
    }
    &__description{
        font-family: FuturaLight;
        font-size: 18px;
    }
    &__innerDescription{
        color: #2ba3f1;
        font-family: FuturaMedium;
        font-size: 18px;
    }
    &__sliders{
        .slick-slider{
            max-width: 780px;
            width: 100%;
            margin: auto;
            box-sizing: border-box;
            display: flex;
            align-items: center;
        }
        .video__btnNext,
        .video__btnPrev{
            position: absolute;
            top: 270px;
            background-color: inherit;
            border: none;
            & svg {
                cursor: pointer;
            }
            &:focus{
                outline: none;
            }
            @media screen and (max-width: 640px) {
                position: static;
            }
        }
        .video__btnNext{
            
            right: -70px;
        }
        .video__btnPrev{
            left: -70px;
        }
    }
    &__slide{
        width: 100%;
        display: flex!important;
        flex-direction: column;
    }
    &__btnWrap{
        box-sizing: border-box;
        padding: 0px 5px;
        @media screen and (max-width: 640px) {
            width: 90%;
        }
    }
    &__btn{
        width: 100%;
        background-color: #fff;
        border-radius: 50px;
        padding: 10px;
        color: #2ba3f1;
        border: 1px solid transparent;
        font-family: FuturaMedium;
        font-size: 22px;
        outline: none;
        cursor: pointer;
        transition: .3s;
        &:hover{
            border: 1px solid #0987db;
            transition: .3s;
        }
        &:focus{
            outline: none;
        }
        &:active{
            outline: none;
        }
        @media screen and (max-width: 768px) {
            width: 100%;
            font-size: 18px;
        }
        @media screen and (max-width: 640px) {
            width: 100%;
            font-size: 20px;
        }
        @media screen and (max-width: 425px) {
            width: 100%;
            font-size: 16px;
        }
    }
}
// .player-wrapper {
//     width: auto; // Reset width
//     height: auto; // Reset height
//   }
//   .react-player {
//     padding-top: 56.25%; // Percentage ratio for 16:9
//     position: relative; // Set to relative
//   }
  
//   .react-player > video {
//     position: absolute; // Scaling will occur since parent is relative now
//     top: 0;
//     width: 100%;
//   }

  .box-player {
      padding-top: 56.25%; // Percentage ratio for 16:9
      position: relative; // Set to relative
      & iframe {
          position: absolute; // Scaling will occur since parent is relative now
          top: 0;
          width: 100%;
      }
  }

  
