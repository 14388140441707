.footer {
  background-color: #2ba3f1;
  box-sizing: border-box;
  padding: 30px 0px 80px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.sochial {
  display: flex;

  &__link {
    max-width: 50px;
    width: 100%;
    margin: 0px 10px;
    transform: translateY(0px);
    transition: .3s;

    &:hover {
      transform: translateY(-3px);
      transition: .3s;
    }
  }

  &__img {
    width: 100%;
  }
}

.contact {
  &__text {
    color: #fff;
    font-family: FuturaMedium;
    font-size: 18px;
  }

  &__link {
    color: #fff;
    font-family: FuturaMedium;
    font-size: 18px;
  }
}

.footer-store {
  display: flex;

  .store-btns {
    z-index: 0;
    max-width: 425px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 640px) {
      padding-top: 0%;
    }

    &__box {
      @media screen and (max-width: 640px) {
        margin-top: 0;
        width: 100%;
      }

    }

    &__link {
      max-width: 160px;
      width: 100%;
      background-color: inherit;
      border: none;
      margin: 0px 5px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: 640px) {
        margin-right: 0;
        max-width: 160px;
      }

      @media screen and (max-width: 320px) {
        margin-right: 0;
        max-width: 150px;
      }
    }

    &__img {
      width: 100%;

      border-radius: 10px;
      border: .3px solid transparent;
      transition: .3s;

      &:hover {
        border: .3px solid rgb(160, 160, 160);
        border-radius: 10px;
        box-shadow: 0px 0px 14px 4px rgb(160, 160, 160);
        transition: .3s;
      }
    }
  }
}

.copy {
  font-family: FuturaLight;
  font-size: 16px;
  color: #0e125d;
  text-align: center;
}
