.navigation{
    display: block;
    &__list{
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 0;
        @media screen and (max-width : 991px) {
            position: absolute;
            flex-direction: column;
            max-width: 640px;
            width: 100%;
            right: 0;
            top: 40px;
            background-color: #2ba3f1;
            align-items: flex-start;
            padding: 20px;
            transform: translateX(100%);
            transition: .5s;
            box-sizing: border-box;
            z-index: 1000;
        }
        &-show{
            transform: translateX(0%);
            transition: .5s;
        }
    }
    &__item{
        padding: 20px 10px;
        list-style: none;
        transition: .3s;
        cursor: pointer;
        box-sizing: border-box;
        &:hover{
            background-color: #4eb6fc;
            transition: .3s;
        }
        @media screen and (max-width : 991px) {
            padding: 10px 0px;
        }
    }
    &__link{
        font-family: FuturaLight;
        color: #fff;
        font-size: 22px;
        text-decoration: none;
    }
    &__menu{
        display: none;
        @media screen and (max-width : 991px) {
            display: block;
        }
    }
}