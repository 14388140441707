
.first-screen {
  position: relative;
  box-sizing: border-box;
  padding: 30px 0px;
  display: block;
  height: 100%;
  background-color: rgb(193, 240, 255);
  overflow: hidden;
  z-index: 0;

  &__title {
    position: relative;
    font-family: FuturaMedium;
    font-size: 42px;
    font-weight: 500;
    color: #fff;
    z-index: 100;
    margin: 0;
    width: 57%;
    height: 160px;
    margin-left: auto;
    text-align: right;
    @media only screen and (max-width: 1366px) {
      width: 75%;
    }
    @media screen and (max-width: 1280px) {
      width: 75%;
    }
    @media screen and (max-width: 1185px) {
      width: 75%;
      font-size: 36px;
    }
    @media screen and (max-width: 640px) {
      width: 65%;
      font-size: 24px;
      margin-left: 0;
      margin-right: auto;
      text-align: left;
      font-weight: 500;
    }

    &-color {
      color: #2ba3f1;
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &__phone {
    position: relative;
    max-width: 300px;
    width: 100%;
    z-index: 100;
    margin-left: auto;
    display: block;
    @media screen and (max-width: 1366px) {
      max-width: 250px;
    }
    @media screen and (max-width: 1185px) {
      max-width: 220px;
    }
    @media screen and (max-width: 1024px) {
      max-width: 180px;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &__subtitle {
    font-family: FuturaMedium;
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    margin: 0px 0px 0px 170px;
    @media screen and (max-width: 1280px) {
      margin-left: 120px;
    }
    @media screen and (max-width: 1024px) {
      margin-left: 70px;
    }
  }
}

.store-btns {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 100;
  padding-top: 30px;
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
    padding-top: 180px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding-top: 50px;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column-reverse;
    padding-top: 60%;
  }
  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
    padding-top: 45%;
  }

  &__box {
    display: flex;


    @media screen and (max-width: 991px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 640px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &__link {
    max-width: 220px;
    width: 100%;
    margin-right: 20px;
    background-color: inherit;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @media screen and (max-width: 640px) {
      margin-right: 0;
      max-width: 165px;
    }
    @media screen and (max-width: 320px) {
      margin-right: 0;
      max-width: 150px;
    }
  }

  &__img {
    width: 100%;
    border-radius: 10px;
    border: .3px solid transparent;
    transition: .3s;

    &:hover {
      border: .3px solid rgb(160, 160, 160);
      border-radius: 10px;
      box-shadow: 0px 0px 14px 4px rgb(160, 160, 160);
      transition: .3s;
    }
  }
}

.cocket-block {
  position: fixed;
  left: 100px;
  right: 100px;
  z-index: 100;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #2ba3f0;

  @media screen and (max-width: 768px) {
    border-radius: 20px;
    padding: 5px 20px 5px 10px;
    left: 10px;
    right: 10px;
  }

  &__text {
    font-size: 16px;
    text-align: center;
    color: #2ba3f0;

    @media screen and (max-width: 768px) {
      text-align: left;
      font-size: 12px;
    }
  }

  &__close {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #2ba3f0;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:before {
      position: absolute;
      content: "";
      width: 20px;
      height: 3px;
      background-color: #2ba3f0;
      transform: rotate(45deg);
    }

    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 3px;
      background-color: #2ba3f0;
      transform: rotate(135deg);
    }
  }
}
