@import './assets/scss/fonts.scss';
@import './assets/scss/variables.scss';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
*{
  user-select: none;
}
input,
textarea {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;  
}
.App {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.container{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  @media screen and (max-width : 1600px) {
    padding: 0px 45px;
  }
  @media screen and (max-width : 1024px) {
    padding: 0px 15px;
  }
}

.title-h2{
  font-family: FuturaMedium;
  font-size: 42px;
  color: #2ba3f1;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  @media screen and (max-width: 640px) {
      width: 100%;
      font-size: 22px;
      text-align: left;
      font-weight: 500;
  }
  &__subTitle{
    font-family: FuturaMedium;
    font-size: 42px;
    color: #2ba3f1;
    width: 70%;
    margin: 20px auto;
    text-align: center;
    font-weight: 500;
  @media screen and (max-width: 640px) {
      width: 100%;
      font-size: 22px;
      text-align: left;
      font-weight: 500;
      margin-bottom: 0;
  }
  }
  &__innertitle{
    font-family: agriculture;
    font-weight: bold;
    font-size: 42px;
    color: rgb(1, 0, 70);
    margin-right: 10px;
    font-weight: 500;
    @media screen and (max-width: 640px) {
        font-size: 20px;
        text-align: left;
    }
  }
}
.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color:#dff1fd;
  z-index: 1000;
  border: none;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .form-request{
    position: relative;
    overflow-y: auto;
  }
  &:focus{
    outline: none;
  }
  .title-h2{
    font-size: 22px;
  }
    @media screen and (max-width:640px) {
    top: 0;bottom: 0;left: 0;right: 0;
    border-radius: 0;
  }
  &__close{
    background-color: inherit;
    border: none;
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 1000;
    &:focus{
      outline: none;
    }
    @media screen and (max-width:640px) {
      top: 10px;
      right: 10px;
    }
  }
  &__text-wrap{
    max-width: 640px;
    margin: 30px auto;
  }
  &__text{
    font-family: FuturaMedium;
    font-size: 22px;
    color: #333;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 36, 36, 0.658);
}

