.category{
    width: 100%;
    box-sizing: border-box;
    padding: 30px 0px;
    &__content{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 10px;
        @media screen and (max-width: 991px){
            justify-content: center;
        }
        @media screen and (max-width: 640px) {
            flex-direction: column;
        }
    }
    &__col{
        display: block;
        width: 33.3%;
        box-sizing: border-box;
        padding: 20px;
        @media screen and (max-width: 991px){
            width: 50%;
        }
        @media screen and (max-width: 640px) {
          width: 100%;
          padding: 0;
          margin-top: 20px;
        }
    }
    &__item{
        border-radius: 50px;
        background-color: #e9f5fe;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        @media screen and (max-width: 640px) {
            height: 50px;
        }
    }
    &__wrapIcon{
        width: 100px;
        height: 100px;
        @media screen and (max-width: 640px) {
            height: 30px;
            width: 30px;
            padding-bottom: 9px;
            padding-left: 9px;
        }
    }
    &__icon{
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin: 5px 0px 0px 5px;
        @media screen and (max-width: 640px) {
            display: none;
        }
    }
    &__icon-mobile{
        display: none;
        @media screen and (max-width: 640px) {
            display: block;
            width: 100%;
            height: 30px;
            object-fit: scale-down;
            margin: 5px 0px 0px 5px;
        }
    }
    &__text{
        font-family: FuturaLight;
        font-size: 22px;
        color: #000;
        box-sizing: border-box;
        padding: 0px 20px;
        margin: 0;
    }
    &__moreText{
        font-family: FuturaMedium;
        font-size: 22px;
        color: #2ba3f1;
        box-sizing: border-box;
        padding: 0px 20px;
        margin: 20px 0px;
        text-align: center;
        width: 100%;
    }
}