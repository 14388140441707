.slide-wrap {
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    opacity: 0.8;
  }
}

.slide {
  width: 100%;
  object-fit: cover;
  @media screen and (max-width: 640px) {
    //  display: none!important;
  }

}

.only-mobile {
  display: none;
  @media screen and (max-width: 640px) {
    display: block;
    width: 100%;
    object-fit: cover;
  }
}
