.appeal{
    background-color: #dadadd;
    background-image: url(../../../assets/images/slider-first/BabyCourse_Desktop_Main_11.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    padding: 30px 0px;
    box-sizing: border-box;

    @media screen and (max-width: 991px) {
        background-image: url(../../../assets/images/slider-first/BabyCourse_Mobile_20.jpg);
        background-position: left;
    }
    .store-btns{
        z-index: 0;
        @media screen and (max-width: 640px) {
            padding-top: 0%;
        }
        &__box{
            @media screen and (max-width: 640px) {
                margin-top: 0;
            }
            
        }
    }
    &__content{
        width: 50%;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        @media screen and (max-width:1440px) {
            width: 60%;
        }
        @media screen and (max-width:1280px) {
            width: 65%;
        }
        @media screen and (max-width:1170px) {
            width: 70%;
        }
        @media screen and (max-width: 991px) {
            width: 80%;
            margin: auto;
        }
        @media screen and (max-width: 640px) {
            width: 100%;
            margin: auto;
        }
        @media screen and (max-width: 375px) {
            width: 100%;
        }
    }
    &__title{
        font-family: FuturaMedium;
        font-size: 42px;
        color: #2ba3f1;
        display: flex;
        align-items: center;
        line-height: 50px;
        margin: 0;
        font-weight: 500;
        @media screen and (max-width:1280px) {
            font-size: 36px;
        }
        @media screen and (max-width:991px) {
            font-size: 24px;
            line-height: 25px;
         }
        @media screen and (max-width: 640px) {
            font-size: 22px;
            line-height: 20px;
        }
        @media screen and (max-width: 375px) {
            font-size: 16px;
        }
       
    }
    &__innertitle{
        font-family: FuturaMedium;
        font-size: 100px;
        color: #2ba3f1;
        display: flex;
        flex-direction: row;
        margin-right: 10px;
        margin-top: 10px;
        @media screen and (max-width:1280px) {
           font-size: 76px;
        }
        @media screen and (max-width:991px) {
           font-size: 72px;
        }
        @media screen and (max-width: 640px) {
            font-size: 72px;
        }
        @media screen and (max-width: 375px) {
            font-size: 48px;
        }
    }
    &__wrapimage{
        max-width: 400px;
        width: 100%;
        margin: 50px auto;
        @media screen and (max-width:1280px) {
            max-width: 250px;
            margin: 20px auto;
         }
    }
    &__bigimage{
        width: 100%;
    }
    &__btns{
        width: 100%;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 640px) {
            justify-content: space-between;
            width: 100%;
        }
    }
    &__link{
        max-width: 220px;
        width: 100%;
        margin-right: 20px;
        @media screen and (max-width: 640px) {
            margin-right: 0;
            max-width: 160px;
        }
        @media screen and (max-width: 320px) {
            margin-right: 0;
            max-width: 150px;
        }
    }
    &__img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: .3px solid transparent;
        transition: .3s;
        &:hover{
            border: .3px solid rgb(160, 160, 160);
            border-radius: 10px;
            box-shadow: 0px 0px 14px 4px rgb(160, 160, 160);
            transition: .3s;
        }
    }
}