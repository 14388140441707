.form-request {
  background-color: #dff1fd;
  box-sizing: border-box;
  padding: 30px 0px;

  &__content {
    margin-top: 30px;
  }

  &__form {
    max-width: 640px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    background-color: #2ba3f1;
    padding: 40px;
    border-radius: 30px;
    @media screen and (max-width: 640px) {
      padding: 20px;
    }
  }

  &__input {
    width: 100%;
    background-color: #fff;
    font-family: FuturaMedium, serif;
    font-size: 16px;
    color: #000;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px 0px;
    border: .5px solid transparent;
    @media screen and (max-width: 640px) {
      margin: 5px 0px;
    }

    &::placeholder {
      font-family: FuturaMedium, serif;
      font-size: 16px;
      color: #333;
    }

    &:focus {
      background-color: #dff1fd;
      outline: none;
      border: 0.5px solid #333;
    }

    &--error {
      border: .5px solid red;
    }

  }

  &__submit {
    background-color: blue;
    border: none;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: FuturaMedium;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    transform: translateY(0px);
    transition: .3s;

    &:hover {
      box-shadow: 0px 4px 10px 2px rgba(2, 2, 199, 0.432);
      transform: translateY(-3px);
      transition: .3s;
    }
  }

  &__success {
    max-width: 640px;
    width: 100%;
    height: 390px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__successText {
    font-family: FuturaMedium;
    font-size: 28px;
    color: #2ba3f1;
    text-align: center;
    padding-top: 30px;
  }
}

.policy {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px 0px;
  @media screen and (max-width: 640px) {
    padding: 5px 0px 10px 0px;
  }

  &__checkbox {
    background-color: inherit;
    border: none;

    &:focus {
      outline: none;
    }
  }

  &__link {
    color: #fff;
    margin-left: 20px;
  }
}

@supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
  .tick {
    stroke-opacity: 0;
    stroke-dasharray: 29px;
    stroke-dashoffset: 29px;
    animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
    animation-delay: .6s
  }

  .circle {
    fill-opacity: 0;
    stroke: #2ba3f1;
    stroke-width: 16px;
    transform-origin: center;
    transform: scale(0);
    animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
  }
}

@keyframes grow {
  60% {
    transform: scale(.8);
    stroke-width: 4px;
    fill-opacity: 0;
  }
  100% {
    transform: scale(.9);
    stroke-width: 8px;
    fill-opacity: 1;
    fill: #2ba3f1;
  }
}

@keyframes draw {
  0%, 100% {
    stroke-opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

