.lectors {
  box-sizing: border-box;
  padding: 30px 0px;

  .title-h2 {
    width: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__box {
    box-sizing: border-box;
    padding: 0px 15px;
  }

  &__col {
    width: 33.3%;
    box-sizing: border-box;
    padding: 0px 15px;
    display: flex;
    @media screen and (max-width: 768px) {
      width: 100%;
      justify-content: center;
      padding: 15px;
    }
  }

  &__text {
    font-family: FuturaLight;
    font-size: 18px;
    color: #333;
  }

  .slick-prev {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.68);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -40px;

    @media screen and (max-width: 768px) {
      display: none !important;
    }

    &:before {
      width: 15px;
      height: 5px;
      content: "";
      background-color: white;
      display: block;
      transform: rotate(45deg);
      margin-left: 16px;
      margin-top: 2px;
    }


    &:after {
      width: 15px;
      height: 5px;
      content: "";
      background-color: white;
      display: block;
      transform: rotate(135deg);
      margin-left: 16px;
      margin-top: -13px;
    }
  }

  .slick-next {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.68);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -40px;
    transform: rotate(180deg);
    @media screen and (max-width: 768px) {
      display: none !important;
    }

    &:before {
      width: 15px;
      height: 5px;
      content: "";
      background-color: white;
      display: block;
      transform: rotate(45deg);
      margin-left: 16px;
      margin-top: 2px;
    }

    &:after {
      width: 15px;
      height: 5px;
      content: "";
      background-color: white;
      display: block;
      transform: rotate(135deg);
      margin-left: 16px;
      margin-top: -13px;
    }
  }

}

.lector {
  &__card {
    max-width: 425px;
    width: 100%;
  }

  &__wrapImg {
    max-width: 375px;
    margin: auto;

    img {
      width: 100%;
    }

    @media screen and (max-width: 640px) {
      width: 90%;
    }
  }

  &__body {
    margin-top: -10px;
    position: relative;
  }

  &__name {
    width: 100%;
    min-height: 100px;
    background-color: #2ba3f1;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

  &__nameText {
    font-family: FuturaMedium;
    font-size: 22px;
    color: #fff;
    text-align: center;
    font-weight: 500;
  }

  &__skillLIst {
    padding: 0px 40px;
    box-sizing: border-box;
    margin-bottom: 0;
    @media screen and (max-width: 640px) {
      padding: 0px 10px;
      margin-bottom: 20px;
    }
  }

  &__skill {
    list-style: none;
    font-family: FuturaLight;
    font-size: 18px;
    line-height: 20px;
    padding: 3px 0px;
  }
}
