@font-face{
    font-family: AvertaDemo;
    src: url('../fonts/Avertademo.ttf')
  }
  @font-face{
    font-family: FuturaLight;
    src: url('../fonts/FuturaLight.ttf')
  }
  @font-face{
    font-family: FuturaMedium;
    src: url('../fonts/FuturaMedium.ttf')
  }
  @font-face{
    font-family: agriculture;
    src: url('../fonts/Agriculture_Regular.otf')
  }